.shop-content{
    display: flex;
    justify-content: space-between;
}
.shop-products-data{
    display: grid;
    grid-template-columns: repeat(3,1fr);
    grid-gap: 1rem;
}
.shop-produts-content{
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.452);
    padding: 0px 0.6rem 1rem 0.8rem;
}
.shop-produts-content img{
    width: 280px;
}

.price-cart{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1rem;
}
.add-cart-btn {
    background-color: var(--secondary-color) ;
    padding: 0.6rem 1rem;
    font-size: 14px;
    color: var(--white-color);
}