.user-rigiter{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
}
.user-rigiter input{
    padding: 1rem;
    border-radius: 0.5rem;
    border: none;
    outline: none;
    background-color: var(--white-color);
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.555);
    width: 60%;
    margin:0.5rem 0;
}
.user-rigiter button{
    background-color: var(--secondary-color);
    padding: 1rem;
    border-radius: 0.5rem;
    color: var(--white-color);
    cursor: pointer;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.555);
    width: 60%;
    margin:0.5rem 0;
    border: none;
}