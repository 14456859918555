.slider-data{
    display: flex;
    flex-direction: column;
    align-items:flex-start;
    justify-content: center;
}
.slider-content{
    max-width: 600px;
    transform: translate(-50%,-50%);

}
.slider-content p{
    margin: 1rem 0 2rem 0;
}
.slider-btn a:nth-of-type(1){
    background-color: var(--secondary-color);
    padding: 0.6rem 1rem;
    color: var(--white-color);
    border: 2px solid var(--secondary-color); 
    margin-right: 0.5rem; 
}
.slider-btn a:hover:nth-of-type(1){
    background-color: transparent;
    transition: all 0.5s;
    color: var(--primary-color);
}

.slider-btn a:nth-of-type(2){
    background-color: transparent;
    padding: 0.6rem 1rem;
    border: 2px solid var(--secondary-color);  
}

.slider-btn a:hover:nth-of-type(2){
    background-color: var(--secondary-color);
    border: 2px solid var(--secondary-color);  
    transition: all 0.5s;
    color: var(--white-color);

}