.top-bar{

    background-color: var(--primary-color);

}
.top-bar-navigation{
    display: flex;
    justify-content: center;
    align-items: center;
    gap:1rem;
    padding: 0.5rem 0;
    color: var(--white-color);
}
.disc-bar{
    display: flex;
    flex: 1;
}
.top-bar-icons{
    display: flex;
    gap:0.5rem;
}
.user-top-menu ul{
    display: flex;
    gap:0.5rem;
}
.user-top-menu ul>li{
    list-style-type: none;
}
.user-top-menu a{
    color: var(--white-color);
}


/* ======== Main Navbar =============== */
.navbar{
    display: flex;
    justify-content: space-between;
    align-items: center;

}
.logo img{
    width: 280px;
}

.main-nav-bar{
    background-color: var(--primary-color);
    padding: 2rem 0;
    border-top: 1px solid #e7e7e7;
    
}

.navigation-search-bar{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--white-color);
}
.nav-serach input{
    border: none;
    outline: none;
    background: none;
    padding: 0.5rem;
    width: 380px;

}
.search-btn{
    background-color: var(--secondary-color);
    padding: 0.5rem 0.5rem 0.1rem 0.5rem;
}
.search-btn svg{
    color: var(--white-color);
    font-size: 26px;
    
}

.main-menu ul{
    display: flex;
    gap: 1.5rem;
}
.main-menu ul>li{
    list-style-type: none;
}
.main-menu a{
    color: var(--white-color);
}

.menu-cart svg{
    color: var(--white-color);
    font-size: 25px;
    cursor: pointer;
}