* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Lato", sans-serif;
  font-family: "Poppins", sans-serif;
}


/* ================ Global Coloring ======================= */

:root {
  --primary-color: rgba(9, 33, 67, 1);
  --secondary-color: #f4a51c;
  --white-color: #fff;
}
a{
  list-style-type: none;
  text-decoration: none;
  color: var(--primary-color);

}
/* ================ Global Spacing ======================= */

.content-spacing{
  max-width: 90%;
  margin: auto;
}
.content-spacing-tb{
  padding: 4rem 0;
}