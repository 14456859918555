.new-products{
    display: grid;
    grid-template-columns: repeat(4,1fr);
    grid-gap: 1.5rem;

}
.produts-content{
    background-color: var(background-color);
    box-shadow: 0 2px 8px #000000;
    padding: 0 0.7rem 1rem 0.7rem;
}
.produts-content img{
    max-width: 250px;
}